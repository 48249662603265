// CommonJS require for Vue
const { createApp } = require('vue');

// CommonJS require for PrimeVue
const PrimeVue = require('primevue/config').default;
// Require a PrimeVue component
const Button = require('primevue/button').default;

const createVueApp = ({ elID = "#kt_content_container", setup = (props, context) => { } }) => {

    // Create Vue instance
    window.app = createApp({
        components: {
            'Button': Button
        },
        setup: setup,
    }).use(PrimeVue).mount(elID)
    console.log("created vue app!")
    return window.app
}

module.exports = { createVueApp };
